/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, YouTubeAsBackground, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, Subtitle, ContactForm, YouTube } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--style3 --full --parallax" anim={""} name={"uvod"} animS={"5"} parallax={true} fullscreen={true}>
          <YouTubeAsBackground autoplay loop modestbranding mute controls={false} embedId={"ZlS1S2V2pN0"} />
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 flex--center" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 mt--10" anim={"2"} animS={"5"}>
              
              <Title className="title-box title-box--style2 title-box--center ff--2 fs--72 w--300 ls--02 lh--1" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Hudlická Stodola</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--10 pt--10" name={"ryxxlzgclh"} style={{"backgroundColor":"rgba(223,220,209,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--36 w--300" content={"<span style=\"color: var(--black);\"><a href=\"/#kontakt\">Rezervace na rok 2025 jsou spuštěny</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--40" name={"uvod-1"}>
        </Column>


        <Column name={"vitejteunas"} style={{"paddingTop":44,"paddingBottom":84}} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Vítejte u nás"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Naše stodola se nachází na kraji hlubokých křivoklátských lesů, v malebné obci Hudlice s bohatou historií. Z Prahy a Plzně se k nám dostanete za zhruba 45 minut. Ke stodole patří velká zahrada a&nbsp; ovocný sad, který nabízí krásné výhledy do údolí."}>
              </Text>

              <Button className="btn-box btn-box--cbtn2" use={"page"} href={"/galerie-misto"} content={"<span style=\"color: rgb(3, 0, 0);\">Galerie</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/32646/47f55589468e4b268c944138f188b3d3_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/47f55589468e4b268c944138f188b3d3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/47f55589468e4b268c944138f188b3d3_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"nasestodola"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/32646/b369253c74cb41a68f03816d78525718_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/b369253c74cb41a68f03816d78525718_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/b369253c74cb41a68f03816d78525718_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/b369253c74cb41a68f03816d78525718_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Naše stodola"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Naši Hudlickou stodolu jsme přivedli zpět k životu vlastníma rukama. Tvořili jsme jí srdcem a chtěli jsme, aby mohla sloužit i dalším generacím. Vznikl krásný prostor, který snoubí staré krásné, s moderním a nadčasovým. Ve stodole usadíte pohodlně 60 hostů, maximálně 70. Ve stodole najdete i bar pro váš raut a další občerstvení."}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/galerie-protory"} content={"<span style=\"color: rgb(5, 0, 0);\">Galerie</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"svatba"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Svatba"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Sníte o krásné české svatbě ve stodole nebo v jabloňovém sadu? Váš svatební obřad se může uskutečnit kdekoliv na pozemku, například v jabloňovém sadu s výhledem do krajiny a za zvuku bečení oveček. V případě nepřízně počasí může obřad proběhnout i ve stodole u skleněných vrat, takže se nepřipravíte o výhled.<br>Pro více informací nás neváhejte kontaktovat.<br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/galerie-svatba"} content={"<span style=\"color: var(--black);\">Galerie</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/32646/c95739dfd32f413f8fba5b392e0f2f0c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/c95739dfd32f413f8fba5b392e0f2f0c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/c95739dfd32f413f8fba5b392e0f2f0c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/c95739dfd32f413f8fba5b392e0f2f0c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/c95739dfd32f413f8fba5b392e0f2f0c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"spolecenskeakce"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/32646/6d21323f0bcf4c38b956e53428790041_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32646/6d21323f0bcf4c38b956e53428790041_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32646/6d21323f0bcf4c38b956e53428790041_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32646/6d21323f0bcf4c38b956e53428790041_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32646/6d21323f0bcf4c38b956e53428790041_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Společenské akce"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Naše stodola vám může sloužit i jako místo pro uspořádání narozeninových oslav, firemních večírků, školení a mnoho dalšího. "}>
              </Text>

              <Button className="btn-box" content={"<span style=\"color: rgb(5, 0, 0);\">Galerie</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--48 w--300" style={{"color":"var(--color-variable-1)"}} content={"Kontaktujte nás"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":740}} content={"Máte zájem o rezervaci, prohlídku, nebo o bližší informace? Vyplňte kontaktní formulář, a my se vám co nejdříve ozveme"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Jméno","type":"text","required":true,"placeholder":"Zadejte vaše jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Datum konání akce","type":"text"},{"name":"Plánovaný počet hostů","type":"text"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"jheuj9cjnts"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1000}} columns={"1"}>
            
            <ColumnWrapper >
              
              <YouTube embedId={"EnwgdD8oYfE"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Adresa<br>"}>
              </Title>

              <Text className="text-box" content={"Hudlice, okres Beroun<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43 w--300 ls--0 lh--1" content={"Hudlická stodola<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+420 737 282 756<br>info@hudlickastodola.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}